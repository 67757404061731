

















import { Component, Vue } from 'vue-property-decorator';

@Component({
  props: {
    show: { type: Boolean },
  },
})
export default class Modal extends Vue {}
