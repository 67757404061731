



































































import { Component, Vue } from 'vue-property-decorator';

import Chat from './Chat.vue';
import Game from '../game/Game.vue';
import GameRegistration from '../game/GameRegistration.vue';
import Loading from './Loading.vue';
import ViewWrapper from './ViewWrapper.vue';
import Modal from './Modal.vue';
import Tooltip from './Tooltip.vue';
import ClassLogo from '../icons/ClassLogo.vue';

export interface StopStatsInterface {
  // game duration in minutes
  duration: number;
  // list of user stats
  users: {
    isWinner: boolean;
    name: string;
    blockCount: number;
    rowCount: number;
    className: string;
  }[];
}

@Component({
  components: {
    Chat,
    ClassLogo,
    Game,
    GameRegistration,
    Loading,
    Modal,
    Tooltip,
    ViewWrapper,
  },
  props: {
    stopStats: { },
  },
})
export default class StopStatsModal extends Vue {}
