






































import { Component, Vue } from 'vue-property-decorator';
import { onUnmounted, ref } from '@vue/composition-api';
import { WsMessageType } from '@battletris/shared';

import { getCurrentConnection } from '../lib/RoomConnection';

@Component({
  setup(props, { root }) {
    const chat = ref<any[]>([]);
    const conn = getCurrentConnection();
    const usersInLobby = ref<string[]>([]);

    const setUsersInLobby = () => {
      usersInLobby.value = Object
        .keys(conn?.users || {})
        .map((userId) => conn?.users[userId].name) as string[];
    };
    setUsersInLobby();

    const addChatMsg = (userId: string, message: string) => {
      const d = new Date();
      const timeString = [
        d.getHours().toString().padStart(2, '0'),
        d.getMinutes().toString().padStart(2, '0'),
        d.getSeconds().toString().padStart(2, '0'),
      ].join(':');
      chat.value.unshift({
        id: userId,
        message,
        date: timeString,
      });
    };

    if (conn) {
      conn.onMessage((type: WsMessageType, payload: any) => {
        switch (type) {
          case WsMessageType.CHAT: {
            addChatMsg(payload.id, payload.message);
            break;
          }
          case WsMessageType.GAME_STATS: {
            addChatMsg('', payload.message);
            break;
          }
          case WsMessageType.USER_UPDATE:
          case WsMessageType.ROOM_JOIN: {
            setUsersInLobby();
            break;
          }
          case WsMessageType.ROOM_LEAVE: {
            setUsersInLobby();
            break;
          }
          case WsMessageType.GAME_STOP: {
            addChatMsg('', root.$i18n.t('game.ends', {
              winner: conn?.users[payload?.winner].name,
            }));
            break;
          }
        }
      }, onUnmounted);
    }

    const newMessage = ref('');
    const sending = ref(false);
    const getUserName = (id: string) => conn?.users[id]?.name || id;
    const sendMessage = async (event: Event) => {
      event.preventDefault();
      event.stopPropagation();
      sending.value = true;
      await conn?.send(WsMessageType.CHAT, newMessage.value.slice(0, 100));
      newMessage.value = '';
      sending.value = false;
    };

    return {
      chat,
      getUserName,
      newMessage,
      sending,
      sendMessage,
      usersInLobby,
    };
  },
})
export default class ClassLogo extends Vue {}
