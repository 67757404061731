


































































import { onUnmounted, ref } from '@vue/composition-api';
import { Component, Vue } from 'vue-property-decorator';
import {
  GameUserStatus,
  UserInterface,
  WsMessageType,
} from '@battletris/shared';

import UserSetting from '../components/UserSetting.vue';
import RoomConnection, { getCurrentConnection } from '../lib/RoomConnection';
import currUser from '../lib/User';
import ClassLogo from '../icons/ClassLogo.vue';

@Component({
  components: {
    ClassLogo,
    UserSetting,
  },
  props: {
    isOpen: { type: String },
  },
  setup() {
    const activeUserId = ref(currUser.id);
    const conn: RoomConnection = getCurrentConnection() as RoomConnection;
    const isJoined = ref(false);
    const loading = ref(true);
    const registrations = ref<GameUserStatus[]>([]);
    const users = ref<UserInterface[]>([]);
    const userSlots = ref([0, 1, 2, 3, 4]);
    const rerender = ref(false);

    const updateGameUsers = () => {
      const userIds = Object.keys(conn.gameRegistration);
      users.value = userIds.map((userId) => conn.users[userId]);
      registrations.value = userIds.map(
        (userId) => conn.gameRegistration[userId],
      );
      isJoined.value = userIds.includes(currUser.id);
      // force update of other players
      rerender.value = true;
      setTimeout(() => (rerender.value = false), 0);
    };
    updateGameUsers();

    const join = () => conn.send(WsMessageType.GAME_JOIN);
    const leave = () => conn.send(WsMessageType.GAME_LEAVE);
    const start = () => conn.send(WsMessageType.GAME_ACCEPT);
    const stop = () => conn.send(WsMessageType.GAME_CANCEL);
    conn.onMessage(async (type: number) => {
      switch (type) {
        case WsMessageType.USER_UPDATE:
        case WsMessageType.ROOM_JOIN:
        case WsMessageType.GAME_REG_UPDATE: {
          updateGameUsers();
          break;
        }
      }
    }, onUnmounted);

    return {
      activeUserId,
      isJoined,
      join,
      leave,
      loading,
      registrations,
      rerender,
      start,
      stop,
      users,
      userSlots,
    };
  },
})
export default class GameRegistration extends Vue {}
